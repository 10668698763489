import { LANGUAGE_TYPES, LanguageID } from './language';
import { Gender } from './gender';

interface Question {
  A: {
    text: string;
    value: LanguageID;
  },
  B: {
    text: string;
    value: LanguageID;
  },
}

export type Answer = string;
export const isAnswer = (answer: string): answer is Answer => {
  return /^[A-E]{0,30}$/.test(answer);
};
export const summarizeAnswer = (answer: string): SummarizedAnswer => {
  const answerCount: AnswerCount = { A: 0, B: 0, C: 0, D: 0, E: 0 };
  answer.split('').forEach(answer => answerCount[answer as LanguageID]++);
  return Object.values(answerCount).join('_');
}

export type AnswerCount = { [key in LanguageID]: number };
export const maxAnswerCount = (answerCount: AnswerCount): number => {
  return Object.values(answerCount).reduce((a, b) => Math.max(a, b), 0);
}

export type SummarizedAnswer = string;
export const isSummarizedAnswer = (answer: string): answer is SummarizedAnswer => {
  return /^(\d{1,2}_){4}\d{1,2}$/.test(answer);
};
export const parseSummarizedAnswer = (answer: SummarizedAnswer): AnswerCount => {
  const counts = answer.split('_').map(str => Number(str));
  return Object.keys(LANGUAGE_TYPES).reduce((a, b) => ({ ...a, [b]: counts[Object.keys(a).length] }), {} as AnswerCount);
}
export const getLanguageFromAnswerCount = (answerCount: AnswerCount): LanguageID => {
  return Object.keys(answerCount).reduce((a, b) =>
    answerCount[a as LanguageID] >= answerCount[b as LanguageID] ? a : b
  ) as LanguageID;
}

type QuestionSet = { [key in Gender]: Question[] };
export const QUESTIONS: QuestionSet = {
  // for male
  m: [{ // 1
    A: {
      text: '<div><span>' + [
        '彼女から',
        '愛情のこもった',
        'メッセージを',
        'もらうと',
        '気分がいい。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼女に',
        '抱きつかれると',
        '嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
  }, { // 2
    A: {
      text: '<div><span>' + [
        '彼女と二人きりで',
        'いるのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        'ちょっとした用事を',
        '手伝ってくれると、',
        '彼女の愛情を感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    }
  }, { // 3
    A: {
      text: '<div><span>' + [
        '彼女から特別な',
        '贈り物をもらうと',
        '幸せを感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
    B: {
      text: '<div><span>' + [
        '彼女と長期の旅行に',
        '出かけるのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    }
  }, { // 4
    A: {
      text: '<div><span>' + [
        '彼女が私の洋服を',
        '洗濯してくれると',
        '愛情を感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '彼女に',
        '触れられるのが',
        '好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    }
  }, { // 5
    A: {
      text: '<div><span>' + [
        '彼女が肩などに腕を回してくると',
        '「愛されている」と感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '彼女が贈り物で私を驚かせてくれると、',
        '彼女に愛されているとわかる。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    }
  }, { // 6
    A: {
      text: '<div><span>' + [
        '彼女とならどこに',
        '行くのも好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        '彼女と手を',
        'つなぐのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    }
  }, { // 7
    A: {
      text: '<div><span>' + [
        '彼女のくれる贈り物を',
        '大切に思う。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
    B: {
      text: '<div><span>' + [
        '彼女に「愛してる」と',
        '言われるのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    }
  }, { // 8
    A: {
      text: '<div><span>' + [
        '彼女がぴったりとそばに',
        '座ってくれるのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '彼女が「かっこいい」とか',
        '「きまってる」と言ってくれると嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    }
  }, { // 9
    A: {
      text: '<div><span>' + [
        '彼女と一緒に時間を',
        '過ごす時に幸せを感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        'どんなに小さな物でも、',
        '彼女からの贈り物は私にとって大事な物だ。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    }
  }, { // 10
    A: {
      text: '<div><span>' + [
        '彼女に「誇りに思う」と言われる時、',
        '「愛されている」と感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '私のために料理をしてくれると、',
        '彼女に愛されていることがわかる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    }
  }, { // 11
    A: {
      text: '<div><span>' + [
        '何であれ、彼女と一緒に',
        '何かをするのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        '彼女の励ましの',
        '言葉を聞くと嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    }
  }, { // 12
    A: {
      text: '<div><span>' + [
        '彼女が私のために細々したことを',
        'やってくれることは、言葉よりも心にしみる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '彼女を腕に',
        '抱くのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    }
  }, { // 13
    A: {
      text: '<div><span>' + [
        '彼女のほめ言葉は',
        '私にとって重要だ。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼女が私の本当に気にいる物を',
        'プレゼントしてくれるととても嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    }
  }, { // 14
    A: {
      text: '<div><span>' + [
        '彼女のそばに',
        'いるだけで気分がいい。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        '彼女にマッサージ',
        'してもらうのが大好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    }
  }, { // 15
    A: {
      text: '<div><span>' + [
        '私が成し遂げたことに対する',
        '彼女の反応にとても励まされる。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼女が本当はやりたくないことをして',
        '私を助けてくれる時、感動する。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    }
  }, { // 16
    A: {
      text: '<div><span>' + [
        '彼女のキスなら',
        'いつでも歓迎だ。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '私のしたいことに彼女が',
        '心から関心を示してくれると嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    }
  }, { // 17
    A: {
      text: '<div><span>' + [
        '手伝いが必要な時、',
        '彼女は頼りになる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '彼女からもらったプレゼントを',
        '開ける時は、わくわくする。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    }
  }, { // 18
    A: {
      text: '<div><span>' + [
        '彼女が私の外見を',
        'ほめてくれるのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼女が私の話に耳を傾けてくれて、',
        'すぐに否定しないところが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    }
  }, { // 19
    A: {
      text: '<div><span>' + [
        '彼女が近くにいると',
        '触らずにいられない。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '彼女が時々私のためにお使いに',
        '行ってくれることをありがたく思う。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    }
  }, { // 20
    A: {
      text: '<div><span>' + [
        'いろいろと私を助けてくれる',
        '彼女は、表彰されるべきだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '彼女はなんて思いのこもった贈り物を',
        'くれることかと、感動することがある。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    }
  }, { // 21
    A: {
      text: '<div><span>' + [
        '彼女が私に一心の注意を',
        '向けてくれるととても嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        '家をきれいにしておくことは、',
        '大切なサービス行為だと思う。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    }
  }, { // 22
    A: {
      text: '<div><span>' + [
        '私の誕生日に彼女が何を',
        'くれるか、楽しみだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
    B: {
      text: '<div><span>' + [
        '私が大切な存在だということを、',
        '彼女にいつでも何度でも言われたい。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    }
  }, { // 23
    A: {
      text: '<div><span>' + [
        '私にプレゼントをくれることで、',
        '彼女は私への愛を伝えてくれる。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
    B: {
      text: '<div><span>' + [
        '彼女は家の周りの仕事を',
        '手伝うことで愛情を示してくれる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    }
  }, { // 24
    A: {
      text: '<div><span>' + [
        '彼女が私の話を中断しないで',
        '聞いてくれるところが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        '彼女からのプレゼントなら、',
        'いつでもいつまでも嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    }
  }, { // 25
    A: {
      text: '<div><span>' + [
        '彼女は私が疲れていると、それを察して、',
        '何をして手伝ったらいいかと尋ねてくれる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '目的地がどこだろうと、とにかく彼女と',
        '一緒に出かけるのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    }
  }, { // 26
    A: {
      text: '<div><span>' + [
        '彼女とセックスするのが',
        '大好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '彼女のプレゼントに',
        '驚かされるのが大好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    }
  }, { // 27
    A: {
      text: '<div><span>' + [
        '彼女の励ましの言葉が',
        '私に自信を与えてくれる。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼女と映画を',
        '見るのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    }
  }, { // 28
    A: {
      text: '<div><span>' + [
        '彼女からのプレゼントに',
        '勝る贈り物はない。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
    B: {
      text: '<div><span>' + [
        '彼女に触らずには',
        'いられない。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    }
  }, { // 29
    A: {
      text: '<div><span>' + [
        '彼女が、ほかにやることがあるのに',
        '私を手伝ってくれる時、とてもありがたく思う。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '「感謝してる」と彼女が言ってくれると、',
        'ものすごく気分がいい。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    }
  }, { // 30
    A: {
      text: '<div><span>' + [
        'しばらく離れていたあと、',
        '彼女を抱き寄せてキスするのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '私を信頼している、と',
        '彼女に言ってもらうのが好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    }
  }],
  // for female
  f: [{ // 1
    A: {
      text: '<div><span>' + [
        '彼から',
        '優しい言葉で',
        'メッセージを',
        'もらうと',
        '気分がいい。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼に',
        '抱き寄せられると',
        '嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
  }, { // 2
    A: {
      text: '<div><span>' + [
        '彼と',
        '二人きりで',
        'いるのが好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        'ちょっとした',
        '用事を肩代わり',
        'してくれると、',
        '彼からの愛情を',
        '感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
  }, { // 3
    A: {
      text: '<div><span>' + [
        '彼から',
        '特別の贈り物を',
        'もらうと',
        '幸せを感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
    B: {
      text: '<div><span>' + [
        '彼と',
        '長期の旅行に',
        '出かけるのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
  }, { // 4
    A: {
      text: '<div><span>' + [
        '彼が',
        '洗濯の手伝いを',
        'してくれると、',
        '愛情を感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '彼に',
        '触られるのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
  }, { // 5
    A: {
      text: '<div><span>' + [
        '彼が',
        '肩などに',
        '腕を回してくると、',
        '「愛されている」と',
        '感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '彼が',
        '贈り物で',
        '私を',
        '驚かせてくれると、',
        '彼に愛されていることが',
        'わかる。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
  }, { // 6
    A: {
      text: '<div><span>' + [
        '彼となら',
        'どこへ行くのも',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        '彼と',
        '手をつなぐのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
  }, { // 7
    A: {
      text: '<div><span>' + [
        '彼のくれる',
        '贈り物を',
        '大切に思う。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
    B: {
      text: '<div><span>' + [
        '彼に',
        '「愛してる」と',
        '言われるのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
  }, { // 8
    A: {
      text: '<div><span>' + [
        '彼が',
        'ぴったりそばに',
        '座ってくれるのが',
        '好き',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '彼が',
        '「その服、似合ってるよ」とか、',
        '「きれいだ」などと',
        '言ってくれると',
        '嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
  }, { // 9
    A: {
      text: '<div><span>' + [
        '彼と',
        '一緒に時間を',
        '過ごす時に',
        '幸せを',
        '感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        'どんなに小さな物でも、',
        '彼からの贈り物は',
        '私にとって',
        '大事な物。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
  }, { // 10
    A: {
      text: '<div><span>' + [
        '彼に',
        '「誇りに思う」と',
        '言われる時、',
        '「愛されている」と',
        '感じる。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼が',
        '食事の後片づけを',
        '手伝ってくれると、',
        '愛されていることが',
        'わかる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
  }, { // 11
    A: {
      text: '<div><span>' + [
        '何であれ、',
        '彼と',
        '一緒に',
        '何かをするのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        '彼の',
        '励ましの言葉を',
        '聞くと',
        '嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
  }, { // 12
    A: {
      text: '<div><span>' + [
        '彼が',
        '私のために',
        '細々したことを',
        'やってくれることは、',
        '言葉よりも',
        '心にしみる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '彼に',
        '抱きつくのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
  }, { // 13
    A: {
      text: '<div><span>' + [
        '彼の',
        'ほめ言葉は',
        '私にとって',
        '重要だ。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼が',
        '私の本当に気にいる物を',
        'プレゼントしてくれると',
        'とても嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
  }, { // 14
    A: {
      text: '<div><span>' + [
        '彼のそばに',
        'いるだけで',
        '気分がいい。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        '彼に',
        'マッサージ',
        'してもらうのが',
        '大好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
  }, { // 15
    A: {
      text: '<div><span>' + [
        '私が',
        '成し遂げたことに',
        '対する',
        '彼の反応に',
        'とても励まされる。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼が',
        '本当は',
        'やりたくない',
        'ことをして',
        '私を',
        '助けてくれる時、',
        '感動する。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
  }, { // 16
    A: {
      text: '<div><span>' + [
        '彼のキスなら',
        'いつでも大歓迎。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '私の',
        'したいことに',
        '彼が',
        '心から',
        '関心を',
        '示してくれるのが',
        '嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
  }, { // 17
    A: {
      text: '<div><span>' + [
        '手伝いが',
        '必要な時、',
        '彼は',
        '頼りになる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '彼から',
        'もらった',
        'プレゼントを',
        '開ける時は、',
        'わくわくする。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
  }, { // 18
    A: {
      text: '<div><span>' + [
        '彼が',
        '私の外見を',
        'ほめてくれるのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼が',
        '私の話に',
        '耳を傾け、',
        '私の考えを',
        '尊重してくれるのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
  }, { // 19
    A: {
      text: '<div><span>' + [
        '彼が',
        '近くにいると',
        '触らずに',
        'いられない。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '彼が',
        '時々',
        '私のために',
        'お使いに',
        '行ってくれることを',
        'ありがたく思う。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
  }, { // 20
    A: {
      text: '<div><span>' + [
        'いろいろと',
        '私を',
        '助けてくれる',
        '彼は、',
        '表彰されるべきだと',
        '思う。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '彼は',
        'なんて思いのこもった',
        '贈り物を',
        'くれることかと、',
        '感動することがある。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
  }, { // 21
    A: {
      text: '<div><span>' + [
        '彼が私に',
        '一心の注意を',
        '向けてくれると、',
        'とても嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        '彼が',
        '家の掃除を',
        '手伝ってくれることに',
        '感謝している。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
  }, { // 22
    A: {
      text: '<div><span>' + [
        '私の誕生日に',
        '彼が何をくれるか',
        '楽しみ。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
    B: {
      text: '<div><span>' + [
        '私が',
        '大切な存在だと',
        'いうことを、',
        '彼にいつでも',
        '何度でも',
        '言われたい。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
  }, { // 23
    A: {
      text: '<div><span>' + [
        '私に',
        'プレゼントを',
        'くれることで、',
        '彼は私への',
        '愛を伝えてくれる。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
    B: {
      text: '<div><span>' + [
        '頼まなくても',
        '手伝ってくれることで、',
        '私に対する',
        '彼の愛が',
        'わかる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
  }, { // 24
    A: {
      text: '<div><span>' + [
        '彼が',
        '私の話を',
        '中断しないで',
        '聞いてくれるところが',
        '好きだ。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
    B: {
      text: '<div><span>' + [
        '彼からの',
        'プレゼントなら、',
        'いつでも',
        'いつまでも',
        '嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
  }, { // 25
    A: {
      text: '<div><span>' + [
        '彼は私が',
        '疲れていると',
        'それを察して、',
        '何をして',
        '手伝ったらいいかと',
        '尋ねてくれる。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '目的地が',
        'どこだろうと、',
        'とにかく',
        '彼と一緒に',
        '出かけるのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
  }, { // 26
    A: {
      text: '<div><span>' + [
        '彼と',
        '抱き合うのが',
        '大好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '彼の',
        'プレゼントに',
        '驚かされるのが',
        '大好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
  }, { // 27
    A: {
      text: '<div><span>' + [
        '彼の',
        '励ましの言葉が',
        '私に自信を',
        '与えてくれる。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
    B: {
      text: '<div><span>' + [
        '彼と',
        '映画を',
        '見るのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'B',
    },
  }, { // 28
    A: {
      text: '<div><span>' + [
        '彼からの',
        'プレゼントに',
        '勝る贈り物は',
        'ない。',
      ].join('</span><span>') + '</span></div>',
      value: 'C',
    },
    B: {
      text: '<div><span>' + [
        '彼が',
        '私に触らずに',
        'いられないことが',
        '嬉しい。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
  }, { // 29
    A: {
      text: '<div><span>' + [
        '彼が',
        '忙しいのに',
        '私を',
        '手伝ってくれる時、',
        'とてもありがたく',
        '思う。',
      ].join('</span><span>') + '</span></div>',
      value: 'D',
    },
    B: {
      text: '<div><span>' + [
        '「感謝している」と',
        '彼が言って',
        'くれると、',
        'ものすごく',
        '気分がいい。',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
  }, { // 30
    A: {
      text: '<div><span>' + [
        'しばらく',
        '離れていたあと、',
        '彼を抱きしめて',
        'キスするのが',
        '好き。',
      ].join('</span><span>') + '</span></div>',
      value: 'E',
    },
    B: {
      text: '<div><span>' + [
        '私がいなくて',
        '寂しかったと',
        '彼に言われるのが',
        '好き',
      ].join('</span><span>') + '</span></div>',
      value: 'A',
    },
  }],
};
