import React from 'react';
import { ShareButton } from '../atoms/share_button';
import { LanguageID } from "../data/language";

type Props = {
  language: LanguageID,
};

export const ShareButtons: React.FC<Props> = (props: Props) => {
  return (
    <div className="ShareButtons">
      <ShareButton type="facebook" language={ props.language } />
      <ShareButton type="twitter" language={ props.language } />
      <ShareButton type="line" language={ props.language } />
    </div>
  );
};
