import {Link, useLocation} from 'react-router-dom'
import React from 'react';
import { Meta } from './common';

export const PrivacyPolicy: React.FC = () => {
  return (
    <div className='App'>
      <Meta page="Privacy Policy" location={ useLocation() }/>
      <Link to='/'>
        <img className='App-header-logo' src="/logo.png" alt="logo"/>
      </Link>
      <div className="PrivacyPolicy">
        <h3>個人情報の保護について</h3>
        <p>当サイトを利用される方は、以下に記載する諸条件に同意したものとみなします。</p>
        <h3>個人情報の収集について</h3>
        <p>利用者は匿名のまま、当サイトを自由に閲覧することができます。</p>
        <p>当サイトでは、お問い合わせなどの際に、お名前、メールアドレス等の個人情報の開示をお願いいただく場合がございます。</p>
        <h3>個人情報の利用目的</h3>
        <p>これらの個人情報は質問に対する回答や必要な情報を電子メールなどでご連絡する場合に利用させていただきます。 </p>
        <p>個人情報をご提供いただく際の目的以外では利用いたしません。</p>
        <h3>個人情報の第三者への開示</h3>
        <p>当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示・共有することはございません。</p>
        <ul>
          <li>本人のご了解がある場合</li>
          <li>法令等への協力のため、開示が必要となる場合</li>
        </ul>
        <h3>個人情報の開示、訂正、追加、削除</h3>
        <p>ご本人から個人データの開示、訂正、追加、削除をご希望いただいた場合には、本人確認の上、速やかに対応させていただきます。</p>
        <h3>アクセス解析ツールについて</h3>
        <p>当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。 </p>
        <p>このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。</p>
        <p>このトラフィックデータは匿名で収集されており、個人を特定するものではありません。</p>
        <p>この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。</p>
        <p>詳しくは<a href="https://marketingplatform.google.com/about/analytics/terms/jp/">こちら</a>をご覧ください。</p>
        <h3>広告の配信について</h3>
        <p>当サイトでは、第三者配信の広告サービス（Googleアドセンス等）を利用しています。</p>
        <p>このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報 『Cookie』(氏名、住所、メール アドレス、電話番号は含まれません) を使用することがあります。</p>
        <p>Googleアドセンスに関して、このようなCookie機能はブラウザの設定で拒否することができます。 </p>
        <p>詳細は<a href="https://policies.google.com/technologies/ads">こちら</a>をご覧ください。</p>
        <h3>免責事項</h3>
        <p>当サイトの内容や掲載画像等に問題がございましたら、各権利所有者様
          本人が直接メールでご連絡下さい。</p>
        <p>確認後、対応させて頂きます。</p>
        <p>当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。</p>
        <p>当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。</p>
        <p>当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</p>
        <h3>プライバシーポリシーの変更について</h3>
        <p>当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。</p>
        <p>修正された最新のプライバシーポリシーは常に本ページにて開示されます。</p>
      </div>
    </div>
  );
};
