import React, { useState } from 'react';
import { LANGUAGE_TYPES, LanguageID, LanguageType } from "../data/language";
import Modal from "react-modal";

import { AnswerCount, maxAnswerCount } from '../data/question';

const createOpenModalFunc = (setModalContent: Function, setIsOpen: Function) => (languageId: LanguageID) => {
  setModalContent(LANGUAGE_TYPES[languageId]);
  setIsOpen(true);
};

type ResultsProps = {
  answer: AnswerCount,
};

export const LanguageResults: React.FC<ResultsProps> = (props: ResultsProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null as (LanguageType | null));
  const openModal = createOpenModalFunc(setModalContent, setIsOpen);
  const maxScore = maxAnswerCount(props.answer);
  const modalTitleStyle = {
    background: modalContent ? modalContent.buttonColor : "black",
    border: `1px solid ${modalContent ? modalContent.buttonColor : "black"}`,
  };
  const modalAdviseStyle = {
    border: `1px dotted ${modalContent ? modalContent.buttonColor : "black"}`,
    color: modalContent ? modalContent.buttonColor : "black",
  };
  return (
    <div className="LanguageResults">
      {modalContent ?
        <Modal className="Result-modal" isOpen={ modalIsOpen } onRequestClose={ () => setIsOpen(false) }>
          <div className="Result-modal-container">
            <div className="Result-modal-title">
              <p style={ modalTitleStyle }>{ modalContent.label }</p>
            </div>
            <div className="Result-text">
              <p>{ modalContent.description[0] }</p>
              <p>{ modalContent.description[1] }</p>
              <p style={ modalAdviseStyle }>{ modalContent.description[2] }</p>
            </div>
            <button className="Result-modal-button" onClick={ () => setIsOpen(false) }>閉じる</button>
          </div>
        </Modal> : null
      }
      {
        Object.entries(props.answer).map(([key, value]) => (
          <div onClick={ () => openModal(key as LanguageID) }>
            <LanguageResult key={ key } language={ key as LanguageID } max_score={ maxScore } score={ value }/>
          </div>
        ))
      }
    </div>
  );
};

type ResultProps = {
  language: LanguageID,
  score: number;
  max_score: number;
};

export const LanguageResult: React.FC<ResultProps> = (props: ResultProps) => {
  const type = LANGUAGE_TYPES[props.language];
  const { score, max_score } = props;
  const barOutlineStyle = {
    border: `${ type.buttonColor } solid 1px`,
  };
  const barStyle = {
    width: `${ 100 * score / max_score }%`,
    background: type.barColor,
  };
  const buttonStyle = {
    background: type.buttonColor,
  };
  return (
    <div className="LanguageResult">
      <p className="LanguageResult-label">
        { type.label }
        <span className="LanguageResult-button" style={ buttonStyle }>解説▼</span>
      </p>
      <div className="LanguageResult-bar" style={ barOutlineStyle }>
        <div className="LanguageResult-bar-filled" style={ barStyle }/>
      </div>
    </div>
  );
};
