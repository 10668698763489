import * as querystring from 'querystring';
import { Link, useLocation, useParams } from 'react-router-dom'
import React, { useState } from 'react';

import { Error, Meta } from './common';
import { ShareButtons } from '../molecules/share_buttons';
import { LoveLetter } from "../molecules/love_letter";
import { LanguageResults } from '../molecules/language_result';

import { LanguageID, LANGUAGE_TYPES } from '../data/language';
import { getLanguageFromAnswerCount, isAnswer, isSummarizedAnswer, parseSummarizedAnswer, QUESTIONS, summarizeAnswer } from '../data/question';
import { isGender } from '../data/gender';

export const Home: React.FC = () => {
  return (
    <div className='App'>
      <Meta page="TOP" location={ useLocation() }/>
      <div className='App-header'>
        <img className='App-header-logo' src="/top1.png" alt="logo"/>
        <h1 className="App-header-title">どんなときに「愛されている」と感じる？<br/>30の質問であなたの愛情表現タイプが分かる！</h1>
        <img className='Question-decoration' src="/line.png" alt="line"/>
        <div className="App-lead">
          <img className='App-lead-image' src="/top2.png" alt="happy"/>
          <p className="App-lead-text">
            恋愛を長続きさせるには、互いに愛情を注ぐことが大事です。
            しかし「パートナーに愛されている」と実感するポイントは個人差があります。
            自分や相手がどのタイプなのかを知って、相手が喜ぶ方法で好意を伝えましょう。
          </p>
          <img className='App-lead-image' src="/top3.png" alt="choice"/>
          <p className="App-lead-text">
            30の質問に答えることで、どの愛情表現タイプなのかを診断します。
            幸せな2人の姿を思い浮かべながら、2択のうち「素敵だ」と思うほうを選んでください。
          </p>
        </div>
        <div className="Home-starts">
          <Link to='/question/m'>
            <button className="Home-start Home-start-male">男性はこちら</button>
          </Link>
          <Link to='/question/f'>
            <button className="Home-start Home-start-female">女性はこちら</button>
          </Link>
        </div>
        <p className="App-lead-reference">参考文献『愛を伝える5つの方法』</p>
      </div>
    </div>
  );
};

export const Question: React.FC = () => {
  const location = useLocation();
  const { answer = '', gender = 'm' }: { answer?: string, gender?: string } = useParams();
  if (!isAnswer(answer) || !isGender(gender)) {
    return <Error/>;
  }

  const question = QUESTIONS[gender][answer.length];
  const createLink = (option: string) => {
    if (answer.length !== QUESTIONS[gender].length - 1) {
      return `/question/${ gender }/${ answer }${ option }`;
    }
    const summarized = summarizeAnswer(`${ answer }${ option }`);
    return `/finish/${ gender }/${ summarized }`;
  };
  return (
    <div className='App'>
      <Meta page={ `Q${ answer.length + 1 }` } location={ location }/>
      <div className='App-header'>
        <Link to='/'>
          <img className='App-header-logo' src="/logo.png" alt="logo"/>
        </Link>
        <div className='Question-container'>
          <p className='Question-description'>
            幸せな2人の姿を思い浮かべながら、<br/>
            2択のうち「素敵だ」と思うほうを選んでください。
          </p>
          <img className='Question-decoration' src="/line.png" alt="line"/>
          <p className='Question-label'>
            <span>{ answer.length + 1 }</span> / 30
          </p>
          <div className='Question-body'>
            <Link
              to={ createLink(question.A.value) }
              className='Question-option A'
              dangerouslySetInnerHTML={ { __html: question.A.text } }
            />
            <Link
              to={ createLink(question.B.value) }
              className='Question-option B'
              dangerouslySetInnerHTML={ { __html: question.B.text } }
            />
          </div>
          <p className='Question-annotation'>
            ブラウザの戻るボタンを押すと、前の問題をやり直せます。
          </p>
        </div>
      </div>
    </div>
  );
};

export const Finish: React.FC = () => {
  const location = useLocation();
  const [name, setName] = useState('');

  const { summarizedAnswer = '', gender = 'm' } = useParams();
  if (!isSummarizedAnswer(summarizedAnswer) || !isGender(gender)) {
    return <Error/>;
  }

  return (
    <div className='App'>
      <Meta page={ 'Finish' } location={ location }/>
      <div className='App-header'>
        <img className='App-header-logo' src="/logo.png" alt="logo"/>
        <div className='Question-container'>
          <p className='Finish-label'>おつかれさまでした</p>
          <img className='Question-decoration' src="/line.png" alt="line"/>
          <div className='Finish-body'>
            <p className='Finish-description'>お名前を入力してください。<br/>本名でなくてもOKです。</p>
            <div>
              <input type="text" placeholder="あなたの名前" value={ name } onChange={ (e) => setName(e.target.value) }/>
              <Link to={ `/result/${ gender }/${ summarizedAnswer }?${ querystring.stringify({ name }) }` }>
                <button>結果を見る</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Result: React.FC = () => {
  const location = useLocation();

  const { summarizedAnswer = '', gender = 'm' } = useParams();
  if (!isSummarizedAnswer(summarizedAnswer) || !isGender(gender)) {
    return <Error/>;
  }
  window.scrollTo({ top: 0 });

  const sharedName = new URLSearchParams(location.search).get('name') || '未入力';
  const answerCount = parseSummarizedAnswer(summarizedAnswer);
  const language: LanguageID = getLanguageFromAnswerCount(answerCount);
  const description = `私は「${LANGUAGE_TYPES[language].message.label}」に愛情を感じるタイプでした。`;
  return (
    <div className='App'>
      <Meta page={ `${ sharedName }さんの診断結果` } description={ description } location={ location }/>
      <div className='App-header Result-header'>
        <img className='App-header-logo' src="/logo.png" alt="logo"/>
        <h1 className='Result-title'><span>診断結果</span></h1>
        <div className='Result-body'>
          <h2 className='Result-title'>愛情表現タイプ診断 - あなたは何に愛を感じる？</h2>
          <LoveLetter language={ language } from={ sharedName }/>
          <LanguageResults answer={ answerCount }/>
          <img className='Result-decoration-top' src="/line.png" alt="line"/>
          <h3>【アドバイス】</h3>
          <img className='Result-decoration-bottom' src="/line.png" alt="line"/>
          <ol className="Result-advice">
            <li>相手の受け取り方が大事！相手にあった形で愛を伝えましょう！</li>
            <li>我慢する関係は続かない！無理のない範囲で愛を伝えましょう！</li>
            <li>小さな好意をコツコツと！毎日ちょっとずつ愛を伝えましょう！</li>
          </ol>
          <p className="Result-message">
            恋とは不意に落ち、やがて冷めるもの。<br/>
            愛とは意思で築き、いつか花開くもの。<br/>
            ふたりの未来に幸せがありますように！
          </p>
          <img className='Result-decoration-top' src="/line.png" alt="line"/>
          <h3 key="title">この診断を活用するために</h3>
          <img className='Result-decoration-bottom' src="/line.png" alt="line"/>
          <p>大切な人に結果をシェアして、<br/>お互いの愛情表現を知りましょう！</p>
          <ShareButtons language={ language } />
          <img className='Result-decoration-top' src="/line.png" alt="line"/>
          <h3 key="title">はじめから診断する方はこちら</h3>
          <img className='Result-decoration-bottom' src="/line.png" alt="line"/>
          <Link to='/'>
            <button className="Result-back">診断トップへ</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
