import * as querystring from 'querystring';
import { LANGUAGE_TYPES, LanguageID } from './language';

export type SNSType = 'facebook' | 'twitter' | 'line';

interface SNS {
  icon: string;
  createURL: (href: string, language: LanguageID, title: string) => string;
}

const localReplace = (href: string) => href.replace('http://localhost:3000', 'https://5love.indiecoder.jp')

export const SNSs: { [key in SNSType]: SNS } = {
  facebook: {
    icon: '/icons/facebook.png',
    createURL: (href: string, language: LanguageID, title: string) => (
      `https://www.facebook.com/sharer/sharer.php?${ querystring.stringify({ u: localReplace(href) }) }`
    ),
  },
  twitter: {
    icon: '/icons/twitter.png',
    createURL: (href: string, language: LanguageID, title: string) => {
      const params = {
        url: localReplace(href),
        text: [title, `私は「${ LANGUAGE_TYPES[language].message.label }」に愛情を感じるタイプでした。`].join('\n'),
        hashtags: ['愛情表現タイプ診断']
      };
      return `https://twitter.com/share?${ querystring.stringify(params) }`
    },
  },
  line: {
    icon: '/icons/line.png',
    createURL: (href: string, language: LanguageID, title: string) => {
      const contents = [
        encodeURIComponent(title),
        localReplace(href),
        encodeURIComponent(`私は「${ LANGUAGE_TYPES[language].message.label }」に愛情を感じるタイプでした。`)
      ];
      return `http://line.me/R/msg/text/?${ contents.join('%0A') }`
    },
  },
};
