import { BrowserRouter, Link, Route, Switch, withRouter } from 'react-router-dom'
import React from 'react';

import { Home, Question, Finish, Result } from './pages/main';
import { PrivacyPolicy } from './pages/others';
import './App.scss';

const Footer = withRouter(({ location }) => {
  console.log(location);
  return (
    <div className="footer">
      <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/4wNBxeotinuQXmbFA">お問い合わせ</a>
      {
        location.pathname !== 'privacy_policy' ? (
          <Link to={ '/privacy_policy' }>プライバシーポリシー</Link>
        ) : null
      }
    </div>
  );
});
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/question/:gender/:answer' component={ Question }/>
        <Route path='/question/:gender' component={ Question }/>
        <Route path='/finish/:gender/:summarizedAnswer' component={ Finish }/>
        <Route path='/result/:gender/:summarizedAnswer' component={ Result }/>
        <Route path='/privacy_policy' component={ PrivacyPolicy }/>
        <Route path='/' component={ Home }/>
      </Switch>
      <Footer/>
    </BrowserRouter>
  );
};

export default App;
