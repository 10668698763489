import React from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { Link, useLocation } from "react-router-dom";

const callGtag = (title: string, location: { pathname: string, search: string }) => {
  document.title = title;
  const path = location.pathname + location.search;
  ReactGA.initialize('UA-153679123-1');
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

export type MetaProps = {
  page: string;
  description?: string;
  location: { pathname: string; search: string; }
  image?: string;
}
export const Meta: React.FC<MetaProps> = ({ page, description, location, image = null }) => {
  callGtag(page, location);
  const title = (page ? `${ page } | ` : '') + '愛情表現タイプ診断 - あなたは何に愛を感じる？';
  return (
    <Helmet>
      <title>{ title }</title>
      <meta property="og:title" content={ title }/>
      <meta property="og:site_name" content="愛情表現タイプ診断 - あなたは何に愛を感じる？" />
      <meta property="og:type" content={location.pathname === '/' ? 'website' : 'article'}/>
      <meta property="og:url" content={window.location.href}/>
      <meta property="og:image" content={window.location.origin + (image || '/ogimage.png')}/>
      <meta
        name="og:description"
        content={
          description ||
          `恋愛を長続きさせるには、互いに好意を伝え合うことが大事です。
          ところが「パートナーに愛されている」と実感するポイントは人それぞれです。
          自分や相手がどのタイプなのかを理解した上で、お互いの喜ぶ方法で好意を伝えましょう。

          このサイトでは30の質問に答えることで、どのタイプなのかを診断します。
          幸せな2人の姿を思い浮かべながら、2択のうち「素敵だ」と思うほうを選んでください。`
        }/>
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export const Error: React.FC = () => {
  return (
    <div className='App'>
      <Meta page="Error" location={ useLocation() }/>
      <div className='App-header'>
        <p>不正なリクエストです。</p>
        <p>はじめからやり直してください。</p>
        <Link to='/'>TOPへ</Link>
      </div>
    </div>
  );
};
