import React from 'react';
import { LanguageID, LANGUAGE_TYPES } from "../data/language";


type Props = {
  language: LanguageID,
  from: string;
};

export const LoveLetter: React.FC<Props> = (props: Props) => {
  const message = LANGUAGE_TYPES[props.language].message;
  return (
    <div className="LoveLetter">
      <p className="LoveLetterTitle">{ props.from } さんの診断結果</p>
      <p>
        私は<strong>「{ message.label }」</strong>に愛情を感じるタイプでした。<br/><br/>
        「{ message.examples.join('」や「') }」が好きなので、<strong>{ message.action }と嬉しい</strong>です。
      </p>
    </div>
  );
};
