export type LanguageID = 'A' | 'B' | 'C' | 'D' | 'E';

export interface LanguageType {
  label: string;
  barColor: string;
  buttonColor: string;
  description: string[];
  message: {
    label: string;
    examples: string[];
    action: string;
  };
}

export const LANGUAGE_TYPES: { [key in LanguageID]: LanguageType } = {
  A: {
    label: 'A. 優しい気持ちになる言葉',
    barColor: 'rgba(255, 99, 132, 0.6)',
    buttonColor: 'rgba(255, 99, 132, 0.8)',
    description: [
      'この項目が高い人には、好意的な言葉を掛けてあげましょう。',
      'ぜひ「◯◯◯が素敵だね」「◯◯◯をしてくれて嬉しい」など気持ちを伝えてください。',
      'まずは一言だけでもメッセージを送ってみてはいかがでしょうか。',
    ],
    message: {
      label: '優しい気持ちになる言葉',
      examples: ['感謝されること', '褒められること'],
      action: '好意を伝えてくれる',
    },
  },
  B: {
    label: 'B. 2人で一緒に過ごす時間',
    barColor: 'rgba(54, 162, 235, 0.6)',
    buttonColor: 'rgba(54, 162, 235, 0.8)',
    description: [
      'この項目が高い人には、親密に過ごせるタイミングを作ってあげましょう。',
      'ぜひ「最近の出来事や気持ちを話す」「食事に行く」など時間を分かち合ってください。',
      'まずは10分だけでも会話を楽しんでみてはいかがでしょうか。',
    ],
    message: {
      label: '2人で一緒に過ごす時間',
      examples: ['おしゃべりすること', 'お出かけすること'],
      action: '一緒にいてくれる',
    },
  },
  C: {
    label: 'C. 想いのこめられた贈り物',
    barColor: 'rgba(255, 206, 86, 0.7)',
    buttonColor: 'rgba(215, 166, 6, 0.8)',
    description: [
      'この項目が高い人には、目に見える形でプレゼントを用意してあげましょう。',
      'ぜひ「好きそうなお菓子を買う」「お土産を選ぶ」などサプライズをしてください。',
      'まずは相手が喜びそうなものをメモに書き出してみてはいかがでしょうか。',
    ],
    message: {
      label: '想いのこめられた贈り物',
      examples: ['ちょっとしたお土産', '記念日のお祝い'],
      action: 'サプライズしてくれる',
    },
  },
  D: {
    label: 'D. 手伝いをしてくれること',
    barColor: 'rgba(75, 192, 192, 0.6)',
    buttonColor: 'rgba(65, 172, 172, 0.8)',
    description: [
      'この項目が高い人には、役に立てるよう手助けをしてあげましょう。',
      'ぜひ「ちょっとした用事を手伝う」「雑務を引き受ける」といった貢献をしてください。',
      'まずは何か手伝えることがないか尋ねてみてはいかがでしょうか。'
    ],
    message: {
      label: '手伝いをしてくれること',
      examples: ['頑張ってくれる姿を見ること'],
      action: '声を掛けてくれる',
    },
  },
  E: {
    label: 'E. 触れ合い・スキンシップ',
    barColor: 'rgba(255, 159, 64, 0.6)',
    buttonColor: 'rgba(225, 139, 54, 0.8)',
    description: [
      'この項目が高い人には、スキンシップでぬくもりを分かち合いましょう。',
      'ぜひ「そっと肩に手を置く」「ぎゅっと抱きしめる」など身体を触れ合わせてください。',
      'まずは手を繋いで歩くところから始めてみてはいかがでしょうか。'
    ],
    message: {
      label: '触れ合い・スキンシップ',
      examples: ['手を繋ぐこと', '抱きしめること'],
      action: 'スキンシップをとってくれる',
    },
  }
};
