import React from 'react';
import { LanguageID } from '../data/language';
import { SNSType, SNSs } from '../data/sns';

type Props = {
  type: SNSType,
  language: LanguageID,
  size?: number,
};

export const ShareButton: React.FC<Props> = (props: Props) => {
  const sns = SNSs[props.type];
  const size = props.size || 40;
  const url = sns.createURL(window.location.href, props.language, window.document.title);
  return (
    <a className="ShareButton" href={ url } target="_blank" rel="noopener noreferrer">
      <img width={size} height={size} className="ShareButton-image" src={ sns.icon } alt={ props.type } />
    </a>
  );
};
